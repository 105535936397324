import { hydroponicController, datastore } from '@adaptors';
import { SensorMetadata } from '@types';

export const setCalibration = async (
  sensorAddress: string,
  step: string,
  type: string,
) => {
  return await hydroponicController.post(`/calibrate/${sensorAddress}`, {
    step,
    type,
  });
};

export const updateSensorCalibrationStatus = async (
  sensor: SensorMetadata,
  calibrated: boolean,
) => {
  return await datastore.post(`/sensorMetadata/${sensor.address}`, {
    address: Number(sensor.address),
    calibrated: Number(calibrated),
    calibrationSteps: sensor.calibrationSteps,
    firmwareVersion: sensor.firmwareVersion,
    latestUpdate: Date.now(),
    name: sensor.name,
    type: sensor.type,
  });
};
