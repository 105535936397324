import { ButtonCard, DashboardGrid } from '@components';
import BuildIcon from '@mui/icons-material/Build';
import { Unstable_Grid2 as Grid, useMediaQuery, useTheme } from '@mui/material';
import { RootState } from '@types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const View = () => {
  const { data: sensorMetadata } = useSelector(
    (state: RootState) => state.sensorMetadata,
  );

  const theme = useTheme();
  const naviate = useNavigate();

  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const gridSpacing = smallDevice ? 4 : 6;

  return (
    <DashboardGrid gridSpacing={gridSpacing}>
      {sensorMetadata.map((metadata) => (
        <Grid
          key={`${metadata.address}-calibration-card`}
          xs={12}
          sm={12}
          md={3}
        >
          <ButtonCard
            activeColour={metadata.colour}
            title={`${metadata.type}-${metadata.address}`}
            text={'Calibrate ->'}
            onClick={() => naviate(metadata.address, { relative: 'path' })}
            enabled={!metadata.calibrated}
          />
        </Grid>
      ))}
    </DashboardGrid>
  );
};

export const title = 'Calibration';
export const url = '/calibration';
export const icon = <BuildIcon />;
