import { Alert, Snackbar } from '@mui/material';
import { RootState } from '@types';
import { useSelector } from 'react-redux';
import store, { closeAlert } from '@store';

export const AlertSnackbar = () => {
  const { open, message, severity } = useSelector(
    (state: RootState) => state.alertSnackbar,
  );

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={() => store.dispatch(closeAlert())}
    >
      <Alert severity={severity} sx={{ width: '100%' }} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};
