import { Container, Toolbar, Unstable_Grid2 as Grid } from '@mui/material';
import { ReactNode } from 'react';

interface DashboardGridProps {
  children: ReactNode;
  gridSpacing?: number;
}

export const DashboardGrid = ({
  children,
  gridSpacing = 2,
}: DashboardGridProps) => {
  return (
    <>
      <Toolbar />
      <Container maxWidth="xl" sx={{ mb: 4, mt: 4 }}>
        <Grid container spacing={gridSpacing}>
          {children}
        </Grid>
      </Container>
    </>
  );
};
