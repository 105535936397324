import { useState } from 'react';
import { Dialog, DialogActions, DialogContent, TextField } from '@mui/material';

import LoginIcon from '@mui/icons-material/Login';

import { LoadingButton } from '@mui/lab';

import { authenticate } from 'src/adaptors';
import { AuthenticationStatus } from '@types';

export interface LoginDialogProps {
  userAuthenticated: AuthenticationStatus;
}

export const LoginDialog = ({ userAuthenticated }: LoginDialogProps) => {
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = () => {
    setLoading(true);
    authenticate(userEmail, userPassword).then((loginSuccess) => {
      if (!loginSuccess) {
        setLoading(false);
      } else {
        setTimeout(() => setLoading(false), 2000);
      }
    });
  };

  return (
    <Dialog
      open={userAuthenticated === 'Unauthenticated'}
      onKeyUp={(e) => {
        console.log(e.key);
        if (e.key === 'Enter') {
          handleLogin();
        }
      }}
    >
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="email-address"
          label="Email Address"
          type="email"
          fullWidth
          variant="standard"
          autoComplete="current-email"
          onChange={(update) => setUserEmail(update.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="password"
          label="Password"
          type="password"
          fullWidth
          variant="standard"
          autoComplete="current-password"
          onChange={(update) => setUserPassword(update.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          loadingPosition="end"
          endIcon={<LoginIcon />}
          variant="contained"
          onClick={handleLogin}
        >
          Login
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
