import SensorsIcon from '@mui/icons-material/Sensors';
import { Unstable_Grid2 as Grid, useMediaQuery, useTheme } from '@mui/material';
import { RootState } from '@types';
import { DashboardGrid, GridCard, SensorCard } from '@components';
import { useSelector } from 'react-redux';

export const View = () => {
  const { data: sensorMetadata } = useSelector(
    (state: RootState) => state.sensorMetadata,
  );

  const theme = useTheme();

  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const gridSpacing = smallDevice ? 4 : 6;

  return (
    <DashboardGrid gridSpacing={gridSpacing}>
      {sensorMetadata.map((metadata) => (
        <Grid key={`${metadata.address}-overview-card`} xs={12} sm={6} md={4}>
          <GridCard>
            <SensorCard sensorMetadata={metadata} />
          </GridCard>
        </Grid>
      ))}
    </DashboardGrid>
  );
};

export const title = 'Sensors';
export const url = '/sensors';
export const icon = <SensorsIcon />;
