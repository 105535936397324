import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import {
  connectAuthEmulator,
  getAuth,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import axios from 'axios';

import store, {
  deviceConnectionStatusChanged,
  userAuthenticated,
  createAlert,
  userUnauthenticated,
} from '@store';

import {
  FIREBASE_HOST,
  FIREBASE_PORT,
  FIREBASE_AUTH_PORT,
  FIREBASE_API_KEY,
  DATASTORE_PROJECT,
  ENVIRONMENT,
} from '@constants';

const firebaseConfig = {
  projectId: DATASTORE_PROJECT,
  apiKey: FIREBASE_API_KEY,
};

const app = initializeApp(firebaseConfig);

export const firestore = getFirestore(app);
const auth = getAuth(app);

if (ENVIRONMENT === 'local') {
  connectFirestoreEmulator(firestore, FIREBASE_HOST, FIREBASE_PORT);
  connectAuthEmulator(auth, `http://${FIREBASE_HOST}:${FIREBASE_AUTH_PORT}`, {
    disableWarnings: true,
  });
}

let attemptingConnection = false;

export const authenticate = async (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password)
    .then(() => true)
    .catch((res) => {
      store.dispatch(createAlert({ message: res.message, severity: 'error' }));
      return false;
    });
};

export const attemptFirestoreConnection = (reattempt = 0) => {
  if (reattempt === 0 && attemptingConnection) {
    return;
  }

  if (!attemptingConnection) {
    store.dispatch(
      deviceConnectionStatusChanged({
        device: 'datastore',
        status: 'Pending',
      }),
    );
  }

  attemptingConnection = true;

  if (reattempt > 2) {
    store.dispatch(
      deviceConnectionStatusChanged({
        device: 'datastore',
        status: 'Failed',
      }),
    );
    attemptingConnection = false;
    return;
  }

  axios
    .get(`http://${FIREBASE_HOST}:${FIREBASE_PORT}`)
    .then(() =>
      store.dispatch(
        deviceConnectionStatusChanged({
          device: 'datastore',
          status: 'Succeeded',
        }),
      ),
    )
    .catch(() => {
      setTimeout(() => attemptFirestoreConnection(reattempt + 1), 1000);
    });
};

auth.onAuthStateChanged((user) => {
  if (user === null) {
    store.dispatch(userUnauthenticated());
    return;
  }
  store.dispatch(userAuthenticated());
});

attemptFirestoreConnection();
