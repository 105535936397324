import * as homePage from './home';
import * as sensorsPage from './sensors';
import * as calibrationPage from './calibration';
export * from './child-views';

const views = [homePage, sensorsPage, calibrationPage];

type ViewMetadata = {
  title: string;
  url: string;
  icon: JSX.Element;
  View: () => JSX.Element;
};

export const viewMetadata: ViewMetadata[] = views.map((view) => ({ ...view }));
