import { useSelector } from 'react-redux';

import { RootState } from '@types';
import { Unstable_Grid2 as Grid } from '@mui/material';
import { Home } from '@mui/icons-material';

import { DashboardGrid, GridPaper, SensorReadingsChart } from '@components';

export const View = () => {
  const { data: sensorMetadata } = useSelector(
    (state: RootState) => state.sensorMetadata,
  );

  const { data: sensorReadings } = useSelector(
    (state: RootState) => state.sensorReadings,
  );

  const { authenticated: userAuthenticated } = useSelector(
    (state: RootState) => state.user,
  );

  return (
    <DashboardGrid>
      <Grid xs={12}>
        <GridPaper>
          <SensorReadingsChart
            sensorMetadata={sensorMetadata}
            sensorReadings={sensorReadings}
            appReady={userAuthenticated === 'Authenticated'}
          />
        </GridPaper>
      </Grid>
    </DashboardGrid>
  );
};

export const title = 'Home';
export const url = '/';
export const icon = <Home />;
