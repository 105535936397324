import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SensorReading, SensorReadingState } from '@types';

const initialState: SensorReadingState = {
  data: [],
};

export const readingSlice = createSlice({
  name: 'sensor-readings',
  initialState,
  reducers: {
    updateSensorReadings: (
      state: SensorReadingState,
      action: PayloadAction<SensorReading[]>,
    ) => {
      state.data = action.payload;
    },
  },
});

export const { updateSensorReadings } = readingSlice.actions;

export default readingSlice.reducer;
