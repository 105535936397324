import { createSlice } from '@reduxjs/toolkit';
import { UserState } from '@types';

const initialState: UserState = {
  authenticated: 'Pending',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userAuthenticated: (state: UserState) => {
      state.authenticated = 'Authenticated';
    },
    userUnauthenticated: (state: UserState) => {
      state.authenticated = 'Unauthenticated';
    },
  },
});

export const { userAuthenticated, userUnauthenticated } = userSlice.actions;

export default userSlice.reducer;
