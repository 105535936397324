import { configureStore } from '@reduxjs/toolkit';
import { RootState } from '@types';
import sensorMetadata from './sensor-metadata';
import sensorReadings from './sensor-readings';
import drawer from './drawer';
import deviceConnectivity from './device-connectivity';
import alertSnackbar from './alert-snackbar';
import user from './user';

export * from './sensor-metadata';
export * from './sensor-readings';
export * from './drawer';
export * from './device-connectivity';
export * from './alert-snackbar';
export * from './user';

export default configureStore<RootState>({
  reducer: {
    sensorMetadata,
    sensorReadings,
    drawer,
    deviceConnectivity,
    alertSnackbar,
    user,
  },
});
