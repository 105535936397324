import { ChevronLeft } from '@mui/icons-material';
import {
  Drawer as MuiDrawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useTheme,
} from '@mui/material';
import { RootState } from '@types';
import { useLocation, useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import store, { closeDrawer } from '@store';

import { viewMetadata } from '@views';

interface DrawerHeaderProps {
  leftToRight: boolean;
}

const DrawerHeader = ({ leftToRight }: DrawerHeaderProps) => {
  const theme = useTheme();

  return (
    <IconButton
      sx={{
        marginLeft: leftToRight ? 'auto' : 0,
        color: theme.palette.primary.contrastText,
      }}
      onClick={() => store.dispatch(closeDrawer())}
    >
      <ChevronLeft />
    </IconButton>
  );
};

type DrawerListItemProps = {
  url: string;
  icon: JSX.Element;
  title: string;
  isSelected: boolean;
  navigate: (urlPath: string) => void;
};

const DrawerListItem = ({
  url,
  icon,
  title,
  isSelected,
  navigate,
}: DrawerListItemProps) => {
  const theme = useTheme();

  return (
    <ListItemButton
      key={`${title}-drawerLink`}
      onClick={() => navigate(url)}
      sx={{
        borderBottom: `solid ${theme.palette.text.primary}`,
        backgroundColor: isSelected ? theme.palette.secondary.main : 'inherit',
        ':hover': {
          backgroundColor: theme.palette.secondary.light,
        },
      }}
    >
      <ListItemIcon sx={{ color: theme.palette.text.primary }}>
        {icon}
      </ListItemIcon>
      <ListItemText>{title}</ListItemText>
    </ListItemButton>
  );
};

export const Drawer = () => {
  const theme = useTheme();

  const currentPath = useLocation().pathname;

  const { width, open: drawerOpen } = useSelector(
    (state: RootState) => state.drawer,
  );

  const navigate = useNavigate();

  const drawerHeaderColour =
    theme.palette.mode === 'light'
      ? theme.palette.primary.main
      : 'rgba(255,255,255, 0.09)';

  return (
    <MuiDrawer
      sx={{
        width,
        display: drawerOpen ? 'auto' : 'none',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          outline: 'solid',
          outlineColor: theme.palette.text.primary,
          width,
          boxSizing: 'border-box',
          borderRight: 'none',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar
        style={{
          outline: 'solid',
          outlineColor: theme.palette.text.primary,
          backgroundColor: drawerHeaderColour,
          zIndex: 1,
        }}
      >
        <DrawerHeader leftToRight={theme.direction === 'ltr'} />
      </Toolbar>

      <List sx={{ paddingTop: 0 }}>
        {viewMetadata.map((metadata) => (
          <DrawerListItem
            key={`${metadata.title}-drawer-item`}
            isSelected={
              metadata.url.split('/')[1] === currentPath.split('/')[1]
            }
            navigate={navigate}
            {...metadata}
          />
        ))}
      </List>
    </MuiDrawer>
  );
};
