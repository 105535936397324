import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeviceConnectivityState, ConnectionStatus } from '@types';

const initialState: DeviceConnectivityState = {
  datastore: 'Pending',
  controller: 'Pending',
};

type DeviceStatusChangedType = {
  device: keyof DeviceConnectivityState;
  status: ConnectionStatus;
};

export const connectivitySlice = createSlice({
  name: 'device-connectivity',
  initialState,
  reducers: {
    deviceConnectionStatusChanged: (
      state: DeviceConnectivityState,
      action: PayloadAction<DeviceStatusChangedType>,
    ) => {
      const {
        payload: { device, status },
      } = action;
      state[device] = status;
    },
  },
});

export const { deviceConnectionStatusChanged } = connectivitySlice.actions;

export default connectivitySlice.reducer;
