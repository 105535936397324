import { Routes, Route } from 'react-router-dom';
import { viewMetadata, childViewMetadata } from '@views';

const routes = () => {
  return (
    <Routes>
      {viewMetadata.map(({ title, url, View }) => (
        <Route key={`route-${title}`} path={url} element={<View />} />
      ))}
      {childViewMetadata.map(({ url, View }) => (
        <Route key={`route-${url}`} path={url} element={<View />} />
      ))}
    </Routes>
  );
};

export default routes;
