import {
  BlankCard,
  CalibrationButtons,
  DashboardGrid,
  GridPaper,
  SensorReadingsChart,
} from '@components';
import { Unstable_Grid2 as Grid, useMediaQuery, useTheme } from '@mui/material';
import { RootState } from '@types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const View = () => {
  const theme = useTheme();

  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const gridSpacing = smallDevice ? 4 : 6;

  const { sensorAddress } = useParams();

  const sensorMetadata = useSelector((state: RootState) =>
    state.sensorMetadata.data.find(
      (sensor) => sensor.address === sensorAddress,
    ),
  );

  const sensorReadings = useSelector(
    (state: RootState) => state.sensorReadings.data,
  );

  const { authenticated: userAuthenticated } = useSelector(
    (state: RootState) => state.user,
  );

  if (sensorMetadata === undefined) {
    return <></>;
  }

  return (
    <DashboardGrid gridSpacing={gridSpacing}>
      <Grid xs={12} sm={12} md={3}>
        <BlankCard
          backgroundColour={sensorMetadata.colour}
          title={`${sensorMetadata.type}-${sensorMetadata.address}`}
        />
      </Grid>
      <CalibrationButtons selectedSensor={sensorMetadata} />
      <Grid xs={12}>
        <GridPaper>
          <SensorReadingsChart
            sensorMetadata={[sensorMetadata]}
            sensorReadings={sensorReadings}
            appReady={userAuthenticated === 'Authenticated'}
          />
        </GridPaper>
      </Grid>
    </DashboardGrid>
  );
};

export const url = '/calibration/:sensorAddress';
