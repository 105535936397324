import Axios from 'axios';

import store, { deviceConnectionStatusChanged } from '@store';
import { CONTROLLER_HOST } from '@constants';

let attemptingConnection = false;

export const hydroponicController = Axios.create({
  baseURL: CONTROLLER_HOST,
});

export const attemptControllerConnection = (reattempt = 0) => {
  if (reattempt === 0 && attemptingConnection) {
    return;
  }

  if (!attemptingConnection) {
    store.dispatch(
      deviceConnectionStatusChanged({
        device: 'controller',
        status: 'Pending',
      }),
    );
  }

  attemptingConnection = true;

  if (reattempt > 2) {
    store.dispatch(
      deviceConnectionStatusChanged({ device: 'controller', status: 'Failed' }),
    );
    attemptingConnection = false;
    return;
  }

  hydroponicController
    .get('/healthcheck')
    .then(() =>
      store.dispatch(
        deviceConnectionStatusChanged({
          device: 'controller',
          status: 'Succeeded',
        }),
      ),
    )
    .catch(() => {
      setTimeout(() => attemptControllerConnection(reattempt + 1), 1000);
    });
};

attemptControllerConnection();
