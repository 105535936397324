import {
  CardContent,
  CardHeader as MuiCardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useTheme,
} from '@mui/material';
import { SensorMetadata } from '@types';

const formatTimestamp = (ts: number) => {
  const d = new Date(ts);
  return (
    `${d.getFullYear()}-` +
    `${d.getMonth().toString().padStart(2, '0')}-` +
    `${d.getDate().toString().padStart(2, '0')} ` +
    `${d.getHours()}:${d.getMinutes()}`
  );
};

const SensorInfo = ({
  address,
  calibrated,
  latestUpdate,
  name,
  type,
}: SensorMetadata) => {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>Name:</TableCell>
          <TableCell align="right">{name || '<NULL>'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Address:</TableCell>
          <TableCell align="right">{address}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Type:</TableCell>
          <TableCell align="right">{type}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Calibrated:</TableCell>
          <TableCell align="right">{String(calibrated)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Latest Update:</TableCell>
          <TableCell align="right">{formatTimestamp(latestUpdate)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

type CardHeaderProps = {
  title: string;
  backgroundColor: string;
};

const CardHeader = ({ title, backgroundColor }: CardHeaderProps) => {
  const theme = useTheme();

  return (
    <MuiCardHeader
      title={title}
      style={{ textAlign: 'center' }}
      sx={{ backgroundColor, color: theme.palette.common.white }}
    ></MuiCardHeader>
  );
};

interface SensorCardProps {
  sensorMetadata: SensorMetadata;
}

export const SensorCard = ({ sensorMetadata }: SensorCardProps) => {
  return (
    <>
      <CardHeader
        title={`${sensorMetadata.type}-${sensorMetadata.address}`}
        backgroundColor={sensorMetadata.colour}
      ></CardHeader>
      <CardContent>
        <SensorInfo {...sensorMetadata} />
      </CardContent>
    </>
  );
};
