import { useEffect } from 'react';
import { Box, ThemeProvider } from '@mui/material';

import './styling/App.css';

import Routes from './routes';

import {
  subscribeSensorMetadata,
  subscribeSensorReadings,
} from '@repositories';
import theme from './styling/md-theme';

import { AlertSnackbar, AppBar, Drawer, LoginDialog } from '@components';
import { useSelector } from 'react-redux';
import { RootState } from './types/root-state';

export default function App() {
  const { authenticated: userAuthenticated } = useSelector(
    (state: RootState) => state.user,
  );

  useEffect(() => {
    let metadataSubscription = () => undefined as void;
    let sensorReadingsSubscription = () => undefined as void;

    if (userAuthenticated === 'Authenticated') {
      metadataSubscription = subscribeSensorMetadata();
      sensorReadingsSubscription = subscribeSensorReadings();
    }

    return () => {
      metadataSubscription();
      sensorReadingsSubscription();
    };
  }, [userAuthenticated]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <AppBar />
        <Drawer />
        <Box
          sx={{
            overflow: 'auto',
            flexGrow: 1,
            height: '100vh',
            bgcolor: theme.palette.background.default,
          }}
        >
          <Routes />
        </Box>
        <AlertSnackbar />
      </Box>
      <LoginDialog userAuthenticated={userAuthenticated} />
    </ThemeProvider>
  );
}
