import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { default as ApexChart } from 'react-apexcharts';

import { ChartSeries } from '@types';

const timestampFormatter = (
  timestamp: string | number,
  includeMs = false,
): string => {
  const date = new Date(timestamp);

  const formattedString =
    `${date.getHours().toString().padStart(2, '0')}:` +
    `${date.getMinutes().toString().padStart(2, '0')}:` +
    `${date.getSeconds().toString().padStart(2, '0')}`;

  if (includeMs) {
    return (
      formattedString + `:${date.getMilliseconds().toString().padStart(3, '0')}`
    );
  }

  return formattedString;
};

const formatMs = (timestamp: number) => timestampFormatter(timestamp, true);
const formatNoMs = (timestamp: string) => timestampFormatter(timestamp, false);

interface ChartProps {
  chartData: ChartSeries[];
}

export const Chart = ({ chartData }: ChartProps) => {
  const theme = useTheme();

  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const stokeWidth = smallDevice ? 1 : 2;

  const { palette } = useTheme();

  return (
    <ApexChart
      height={'100%'}
      options={{
        stroke: {
          width: stokeWidth,
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          borderColor: palette.grey[50],
        },
        colors: chartData.map((series) => series.colour),
        chart: {
          animations: {
            enabled: false,
          },
          foreColor: palette.text.primary,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          fontFamily: theme.typography.fontFamily,
        },
        xaxis: {
          type: 'datetime',
          labels: {
            formatter: formatNoMs,
          },
        },
        yaxis: chartData.map((series, index) => ({
          seriesName: series.name,
          opposite: index === 0,
          title: {
            text: series.axisTitle,
          },
        })),
        tooltip: {
          theme: 'dark',
          x: {
            formatter: formatMs,
          },
          fixed: {
            enabled: true,
            position: 'topLeft',
            offsetX: 100,
            offsetY: 40,
          },
        },
      }}
      series={chartData.map((series) => ({
        name: series.name,
        data: series.data,
      }))}
      type={'line'}
    />
  );
};
