import { ButtonCard } from '@components';
import { Unstable_Grid2 as Grid, useTheme } from '@mui/material';
import { RootState, SensorMetadata } from '@types';
import { useState } from 'react';
import store, { createAlert } from '@store';
import { setCalibration, updateSensorCalibrationStatus } from '@repositories';
import { useSelector } from 'react-redux';

const calibrationOrder = ['dry', 'low', 'mid', 'high'];

type CalibrationButtonsProps = {
  selectedSensor: SensorMetadata;
};

export const CalibrationButtons = ({
  selectedSensor,
}: CalibrationButtonsProps) => {
  const { controller: controllerConnectionStatus } = useSelector(
    (state: RootState) => state.deviceConnectivity,
  );

  const calibrationSteps = selectedSensor.calibrationSteps;

  const theme = useTheme();

  const [calibrationStepIndex, setCalibrationStepIndex] = useState(0);
  const [pendingControllerResponse, setPendingControllerResponse] =
    useState(false);

  const nextStep = () => setCalibrationStepIndex(calibrationStepIndex + 1);

  const handleSetCalibration = (
    sensorAddress: string,
    step: string,
    sensorType: string,
  ) => {
    setPendingControllerResponse(true);

    setCalibration(sensorAddress, step, sensorType)
      .then(() => {
        store.dispatch(
          createAlert({
            message: `Succesfully set calibration for <${step.toUpperCase()}> point`,
            severity: 'success',
          }),
        );
        // TECH DEBT: This would ideally be trigered by an event on an event bus.
        if (step === 'high') {
          updateSensorCalibrationStatus(selectedSensor, true);
        }
        nextStep();
      })
      .catch((error) => {
        store.dispatch(
          createAlert({
            message: `Failed to set calibration for <${step.toUpperCase()}> point - ${error}`,
            severity: 'error',
          }),
        );
      })
      .finally(() => setPendingControllerResponse(false));
  };

  const steps = Object.entries(calibrationSteps)
    .sort(
      ([stepOneName], [stepTwoName]) =>
        calibrationOrder.indexOf(stepOneName) -
        calibrationOrder.indexOf(stepTwoName),
    )
    .filter(([, value]) => value)
    .sort(
      ([stepOneName], [stepTwoName]) =>
        calibrationOrder.indexOf(stepOneName) -
        calibrationOrder.indexOf(stepTwoName),
    );

  return (
    <>
      {steps.map(([stepName, value], index) => (
        <Grid key={`${stepName}-set-button`} xs={12} sm={12} md={3}>
          <ButtonCard
            activeColour={
              theme.palette.mode === 'dark'
                ? theme.palette.info.dark
                : theme.palette.info.main
            }
            title={`${stepName.toUpperCase()}${
              typeof value === 'number' ? ': ' + value : ''
            }`}
            text={'Set ->'}
            onClick={() => {
              handleSetCalibration(
                selectedSensor.address,
                stepName,
                selectedSensor.type,
              );
            }}
            enabled={
              calibrationStepIndex === index &&
              !pendingControllerResponse &&
              controllerConnectionStatus === 'Succeeded' &&
              !selectedSensor.calibrated
            }
          />
        </Grid>
      ))}
    </>
  );
};
