interface Array {
  [key: string]: number | string | boolean | object;
}

export function groupArrayByKey<ArrayType extends Array>(
  array: ArrayType[],
  groupKey: string,
): { [key: string]: ArrayType[] } {
  const grouped: { [key: string]: ArrayType[] } = {};
  array.forEach((entry: ArrayType) => {
    const key = entry[groupKey];
    if (typeof key === 'object') {
      throw Error('Invalid Key Type');
    }

    if (grouped[key.toString()] === undefined) {
      grouped[key.toString()] = [];
    }
    grouped[key.toString()].push(entry);
  });

  return grouped;
}
