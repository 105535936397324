import {
  AppBar as MUIAppBar,
  ButtonGroup,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HubIcon from '@mui/icons-material/Hub';
import StorageIcon from '@mui/icons-material/Storage';
import { RootState } from '@types';
import {
  attemptFirestoreConnection,
  attemptControllerConnection,
} from '@adaptors';

import { DeviceConnectivityIndicator } from '@components';

import store, { openDrawer } from '@store';
import { useSelector } from 'react-redux';

const OpenMenuButton = () => {
  const {
    drawer: { open: drawerOpen },
  } = useSelector((state: RootState) => state);

  return (
    <IconButton
      size="large"
      edge="start"
      color="inherit"
      aria-label="menu"
      onClick={() => store.dispatch(openDrawer())}
      sx={{ mr: 2, display: drawerOpen ? 'none' : 'auto' }}
    >
      <MenuIcon />
    </IconButton>
  );
};

const ConnectivityIndicators = () => {
  const {
    controller: controllerConnectionStatus,
    datastore: datastoreConnectionStatus,
  } = useSelector((state: RootState) => state.deviceConnectivity);

  const theme = useTheme();

  return (
    <ButtonGroup
      variant="contained"
      disableElevation
      color="inherit"
      sx={{
        marginLeft: 'auto',
        outline: 'solid',
        outlineColor: theme.palette.text.primary,
      }}
    >
      <DeviceConnectivityIndicator
        name="Datastore"
        connectionStatus={datastoreConnectionStatus}
        reattemptConnectionFunction={attemptFirestoreConnection}
      >
        <StorageIcon />
      </DeviceConnectivityIndicator>
      <DeviceConnectivityIndicator
        name="Controller"
        connectionStatus={controllerConnectionStatus}
        reattemptConnectionFunction={attemptControllerConnection}
      >
        <HubIcon />
      </DeviceConnectivityIndicator>
    </ButtonGroup>
  );
};

export const AppBar = () => {
  const {
    drawer: { width: drawerWidth },
  } = useSelector((state: RootState) => state);

  const theme = useTheme();

  return (
    <MUIAppBar
      position="fixed"
      elevation={0}
      style={{
        outline: 'solid',
        outlineColor: theme.palette.text.primary,
      }}
      sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
    >
      <Toolbar>
        <OpenMenuButton />
        <Typography variant="h5" component="div" noWrap>
          Hydroponic Dashboard
        </Typography>
        <ConnectivityIndicators />
      </Toolbar>
    </MUIAppBar>
  );
};
