import { Paper, Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactNode } from 'react';
import { Property } from 'csstype';

interface GridItemProps {
  children: ReactNode;
  textAlign?: Property.TextAlign;
  height?: string;
  backgroundColour?: string;
}

export const GridPaper = ({
  children,
  textAlign = 'center',
}: GridItemProps) => {
  const theme = useTheme();

  return (
    <Paper
      elevation={4}
      style={{
        padding: theme.spacing(1),
        textAlign,
        borderStyle: 'solid',
      }}
    >
      {children}
    </Paper>
  );
};

export const GridCard = ({
  children,
  height,
  backgroundColour = 'inherit',
}: GridItemProps) => {
  let backgroundImage =
    'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))';
  if (backgroundColour !== 'inherit') {
    backgroundImage = 'none';
  }

  return (
    <Card
      style={{
        borderStyle: 'solid',
        height,
        // backgroundColour: 'inherit',
      }}
      sx={{
        backgroundColor: backgroundColour,
        backgroundImage,
      }}
    >
      {children}
    </Card>
  );
};
