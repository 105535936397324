import { useTheme, CardHeader, CardContent, Typography } from '@mui/material';
import { GridCard } from './grid-item';

type BlankCardProps = {
  title: string;
  backgroundColour?: string;
};

export const BlankCard = ({
  title,
  backgroundColour = 'inherit',
}: BlankCardProps) => {
  const theme = useTheme();

  return (
    <GridCard>
      <CardHeader
        title={title}
        style={{ textAlign: 'center' }}
        sx={{
          backgroundColor: backgroundColour,
          color: theme.palette.common.white,
        }}
      />
      <CardContent
        sx={{
          backgroundColor: backgroundColour,
          color:
            backgroundColour === 'inherit'
              ? theme.palette.background.paper
              : backgroundColour,
          userSelect: 'none',
        }}
      >
        <Typography variant="h5">{'_'}</Typography>
      </CardContent>
    </GridCard>
  );
};
