import {
  collection,
  query,
  onSnapshot,
  limit,
  orderBy,
} from 'firebase/firestore';

import { firestore } from '@adaptors';
import store, { updateSensorMetadata, updateSensorReadings } from '@store';
import { SensorMetadata, SensorReading } from '@types';
import { getSensorColour } from 'src/styling/sensor-styling';

const dataPoints = 200;

export const subscribeSensorReadings = () => {
  const q = query(
    collection(firestore, 'sensorReadings'),
    orderBy('timestamp', 'desc'),
    limit(dataPoints),
  );

  return onSnapshot(q, (querySnapshot) => {
    store.dispatch(
      updateSensorReadings(
        querySnapshot.docs.map((doc) => doc.data() as SensorReading),
      ),
    );
  });
};

export const subscribeSensorMetadata = () => {
  const ref = collection(firestore, 'sensorMetadata');

  return onSnapshot(ref, (querySnapshot) => {
    if (querySnapshot.size === 0) {
      return;
    }

    store.dispatch(
      updateSensorMetadata(
        querySnapshot.docs
          .map((doc) => {
            const docData = doc.data();
            return {
              ...docData,
              address: doc.id,
              colour: getSensorColour(docData.type),
            } as SensorMetadata;
          })
          // Sorts sensor by sensor address in ascending order
          .sort(
            (sensorA, sensorB) =>
              Number(sensorA.address) - Number(sensorB.address),
          ),
      ),
    );
  });
};
