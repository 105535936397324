import { createSlice } from '@reduxjs/toolkit';
import { DrawerState, DrawerWidths } from '@types';

const initialState: DrawerState = {
  open: false,
  width: DrawerWidths.closed,
};

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    openDrawer: (state: DrawerState) => {
      state.open = true;
      state.width = DrawerWidths.open;
    },
    closeDrawer: (state: DrawerState) => {
      state.open = false;
      state.width = DrawerWidths.closed;
    },
  },
});

export const { openDrawer, closeDrawer } = drawerSlice.actions;

export default drawerSlice.reducer;
