import { Box, Skeleton } from '@mui/material';

import { Chart } from '@components';
import { SensorMetadata, SensorReading, ChartSeries } from '@types';
import { groupArrayByKey } from './util';
import { Property } from 'csstype';

const generateChartData = (
  sensorReadings: SensorReading[],
  sensorMetadata: SensorMetadata[],
): ChartSeries[] => {
  const groupedReadings = groupArrayByKey(sensorReadings, 'address');
  const groupedMetadata = groupArrayByKey(sensorMetadata, 'address');

  return Object.keys(groupedMetadata).map((sensorAddress) => ({
    name: `${groupedMetadata[sensorAddress][0].type}-${sensorAddress}`,
    axisTitle: groupedMetadata[sensorAddress][0].type,
    data: (groupedReadings[sensorAddress] || []).map((reading) => [
      reading.timestamp,
      reading.value,
    ]),
    colour: groupedMetadata[sensorAddress][0].colour,
  }));
};

interface SensorReadingsChartProps {
  sensorMetadata: SensorMetadata[];
  sensorReadings: SensorReading[];
  height?: Property.Height;
  appReady: boolean;
}

export const SensorReadingsChart = ({
  sensorMetadata,
  sensorReadings,
  appReady,
  height = '60vw',
}: SensorReadingsChartProps) => {
  return (
    <Box height={height} maxHeight={500}>
      {appReady ? (
        <Chart chartData={generateChartData(sensorReadings, sensorMetadata)} />
      ) : (
        <Skeleton variant="rectangular" height={'100%'} />
      )}
    </Box>
  );
};
