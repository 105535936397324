import { GridCard } from '@components';

import {
  useTheme,
  CardHeader as MuiCardHeader,
  CardContent as MuiCardContent,
  Typography,
} from '@mui/material';

type CardHeaderProps = {
  title: string;
  colour: string;
  enabled: boolean;
};

const CardHeader = ({ title, colour, enabled }: CardHeaderProps) => {
  const theme = useTheme();

  return (
    <MuiCardHeader
      title={title}
      style={{ textAlign: 'center' }}
      sx={{
        backgroundColor: colour,
        color: enabled
          ? theme.palette.common.white
          : theme.palette.text.primary,
      }}
    ></MuiCardHeader>
  );
};

type CardContentProps = {
  text: string;
  colour: string;
  onClick: () => void;
  enabled?: boolean;
};

const CardContent = ({ text, colour, onClick, enabled }: CardContentProps) => {
  const theme = useTheme();

  return (
    <MuiCardContent sx={{ backgroundColor: colour }}>
      <Typography
        variant="h5"
        align="center"
        onClick={enabled ? onClick : () => undefined}
        sx={{
          ':hover': enabled
            ? {
                textDecoration: 'dashed underline',
                cursor: 'pointer',
              }
            : {},
          userSelect: enabled ? 'auto' : 'none',
          color: enabled
            ? theme.palette.common.white
            : theme.palette.background.paper,
        }}
      >
        {text}
      </Typography>
    </MuiCardContent>
  );
};

type ButtonCardProps = {
  activeColour: string;
  title: string;
  text: string;
  onClick: () => void;
  enabled?: boolean;
};

export const ButtonCard = ({
  activeColour,
  title,
  text,
  onClick,
  enabled = true,
}: ButtonCardProps) => {
  const theme = useTheme();

  const colour = enabled ? activeColour : theme.palette.background.paper;

  return (
    <GridCard>
      <CardHeader title={title} colour={colour} enabled={enabled} />
      <CardContent
        text={text}
        colour={colour}
        onClick={onClick}
        enabled={enabled}
      />
    </GridCard>
  );
};
