export const FIREBASE_HOST = process.env.REACT_APP_FIREBASE_HOST || '';
export const FIREBASE_PORT = Number(process.env.REACT_APP_FIREBASE_PORT || '');
export const FIREBASE_AUTH_PORT = Number(
  process.env.REACT_APP_FIREBASE_AUTH_PORT || '',
);
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY || '';
export const DATASTORE_PROJECT = process.env.REACT_APP_DATASTORE_PROJECT || '';
export const CONTROLLER_HOST = process.env.REACT_APP_CONTROLLER_HOST || '';
export const DATASTORE_HOST = process.env.REACT_APP_DATASTORE_HOST || '';
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'local';
