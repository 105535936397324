import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SensorMetadata, SensorMetadataState } from '@types';

const initialState: SensorMetadataState = {
  data: [],
};

export const metadataSlice = createSlice({
  name: 'sensor-metadata',
  initialState,
  reducers: {
    updateSensorMetadata: (
      state: SensorMetadataState,
      action: PayloadAction<SensorMetadata[]>,
    ) => {
      state.data = action.payload;
    },
  },
});

export const { updateSensorMetadata } = metadataSlice.actions;

export default metadataSlice.reducer;
