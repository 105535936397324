import { AlertColor } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertSnackBarState } from '@types';

const initialState: AlertSnackBarState = {
  open: false,
  message: '',
  severity: 'info',
};

type CreateAlertAction = {
  severity: AlertColor;
  message: string;
};

export const snackbarSlice = createSlice({
  name: 'snackbar-alert',
  initialState,
  reducers: {
    createAlert: (
      state: AlertSnackBarState,
      action: PayloadAction<CreateAlertAction>,
    ) => {
      const { severity, message } = action.payload;
      state.open = true;
      state.message = message;
      state.severity = severity;
    },
    closeAlert: (state: AlertSnackBarState) => {
      state.open = false;
    },
  },
});

export const { createAlert, closeAlert } = snackbarSlice.actions;

export default snackbarSlice.reducer;
