import { Button } from '@mui/material';
import { ConnectionStatus } from '@types';

enum connectivityColourMap {
  'Succeeded' = 'success',
  'Pending' = 'warning',
  'Failed' = 'error',
}

type DeviceConnectivityIndicatorProps = {
  name: string;
  connectionStatus: ConnectionStatus;
  reattemptConnectionFunction: () => void;
  children: JSX.Element;
};

export const DeviceConnectivityIndicator = ({
  name,
  connectionStatus,
  reattemptConnectionFunction,
  children,
}: DeviceConnectivityIndicatorProps) => {
  const isPending = connectionStatus === 'Pending';

  return (
    <Button
      onClick={() => reattemptConnectionFunction()}
      disabled={isPending}
      color={connectivityColourMap[connectionStatus]}
      title={`${name} Connection: ${connectionStatus}`}
    >
      {children}
    </Button>
  );
};
