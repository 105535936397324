const sensorColourPalette = {
  PH: '#aa2e25',
  EC: '#008c3a',
};

export const getSensorColour = (sensorType: 'string') => {
  if (!Object.keys(sensorColourPalette).includes(sensorType)) {
    return 'white';
  }

  return sensorColourPalette[sensorType as 'PH' | 'EC'];
};
